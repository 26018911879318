.about-one{
  height: 650px;
  background-color: white;
  display:flex;
  justify-content: center;
  align-items: center;
}
.about-two{
  height: 650px;
  background-color: var(--light-background);
  display:flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #e4e0fe;
}

@media only screen and (max-width: 1024px) {
  .about-one, .about-two{
    height: 45vh
  }
}

@media only screen and (max-width: 450px) {
  .about-one, .about-two {
    height: auto;
    padding: 10% 2%;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {
  .about-one, .about-two {
    height: 80vh
  }
}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {
  .about-one, .about-two {
    height: 80vh
  }
}
