.use-case-container{
  width: 100%;
  height: auto;
  padding: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.use-case-problems{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%
}
.use-case-problems>div{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.use-case-problems *{
  font-family: escala;
  font-size: 20px;
  color: var(--font2);
}
.use-case-problems .use-case-problems-title{
  font-family: escala-bold;
  font-size: 36px;
  color: var(--font2);
  margin-bottom: 0.5em
}
.use-case-container img{
  width: 50%;
  padding: 0 8vw
}
.use-case-apis{
  width:100%;
  min-height: 745px;
  background-color: #F5F6F8;
  border-top: solid 1px #E4E0FE;
  border-bottom: solid 1px #E4E0FE;
  padding: 0 120px 120px 120px;
}
.use-case-apis-title{
  font-family: escala-bold;
  font-size: 48px;
  color: var(--font2);
  margin-bottom: 0.5em;
  padding: 50px 0
}


@media only screen and (max-width: 450px) {
  .use-case-problems{
    width: 75%;
  }
}

@media only screen and (max-width: 1024px) {
  .use-case-container {
    height: auto;
    flex-direction: column-reverse;
    padding: 0;
    padding-top: 10%
  }
  .use-case-problems{
    width: 75%;
    padding-bottom: 40px
  }
  .use-case-container img{
    width: 35%;
    padding: 0
  }
  .use-case-apis-title{
    padding: 20px 40px;
  }

}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {

}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {
  .use-case-container {
      height: auto;
      padding: 10% 0;
    }
}
