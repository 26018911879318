@font-face {
    src: url(../../src/fonts/SourceSansPro-Regular.ttf);
    font-family: escala
}
@font-face {
    src: url(../../src/fonts/SourceSansPro-SemiBold.ttf);
    font-family: escala-bold
}
@font-face {
    src: url(../../src/fonts/SourceSansPro-Light.ttf);
    font-family: escala-light
}

*{
  box-sizing: border-box;
  font-family: 'escala', sans-serif;
}
html{
  font-size: 18px;
}
body{
  height:100vh;
  width:100%;
  margin:0;
  --font: #5a6f7b;
  --font2: #232A3D;
  --main-color: #B80703;
  --font-on-main: white;
  --light-background: #f5f6f8;
  color: var(--font)
}
a{
  text-decoration: none;
  color: var(--font)
}
a:visited{
  text-decoration: none;
  color: var(--font)
}
*:focus{
  outline: none
}
.main-navbar-container{
  width: 100%;
  height: auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main-navbar-container>.language-section{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding: 0 4%;
  background: #f5f6f8
}
.navbar-container{
  height:100px;
  width: 100%;
  display:flex;
  justify-content: space-between;
  padding: 0% 5%;
}
.navbar-container>*{
  display:flex;
  align-items: center;
}
.navbar-container *{
  margin: 0;
  font-size: 1.25rem
}
.navbar-logo-container{
  justify-content: flex-start;
  width:35%;
  align-items: flex-end;
  padding-bottom: 10px
}
.navbar-logo-container>a{
  margin-right: 1em
}
.navbar-logo-container img{
  width: 140px;
  object-fit: cover;
  height: auto;
}
.nav-links{
  display:flex;
  justify-content: space-between;
  min-width: 580px;
  /* position:relative */
}
.nav-links>*{
  width: 25%;
  text-align: center;
  cursor: pointer;
  transition: 0.4s;
  white-space: nowrap;
  margin-left: 1rem;
}
.nav-links>a, .nav-links>a>p {
  height:100%;
  display:flex;
  align-items: center;
}
.nav-links>a:hover{
  color: var(--main-color)
}
.separator{
  width: 3px;
  height: 2rem;
  border: 1px solid  var(--main-color);
  background-color: var(--main-color);
  /* margin: 0 1rem */
}
.language-container{
  display:flex;
  align-items: center;
  margin-left: 2em
}
.language-container *{
  color: var(--font) !important
}
.switch-language{
  padding: 0.5em;
}
.compressed-navbar-container{
  display:none;
}
.sub-menu{
  display:block;
  position:absolute;
  top: 150px;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(80, 80, 80, 0.6);
  z-index: 999;
  width: 100%
}
/* .nav-links>.mega-menu:hover ~ .sub-menu{
  display:block
} */

.sub-menu .submenu-container{
  /* display:flex;
  justify-content: space-between;
  align-items: flex-start; */
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
      "message columns";
  height: auto
}
.sub-menu .submenu-container>.message{
  box-sizing: border-box;
  background-color: #f5f6f8;
  padding: 90px 0 0 120px;
  grid-area: message
}
.sub-menu .submenu-container>.message>h1{
  font-size: 1.5rem;
  font-family: escala-bold;
  margin-bottom:0.5em;
  text-align: left;
  color: var(--main-color)
}
.sub-menu .submenu-container>.message>p{
  white-space: break-spaces;
  text-align: left;
}
.sub-menu .submenu-container>.columns{
  grid-area: columns;
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
}
.sub-menu .submenu-container .column{
  height: 100%;
  padding: 2%;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.sub-menu .submenu-container .column .use-case{
  display:flex;
  justify-content: flex-start;
  align-items: center;
  white-space: break-spaces;
  text-align: left;
  margin-bottom: 1em
}
.sub-menu .submenu-container .column .use-case:hover p{
  color: var(--main-color)
}
.sub-menu .submenu-container .use-case > * {
  font-size: 35px
}
.sub-menu .submenu-container .use-case > p {
  font-size: 24px;
  margin-left: 1em
}
.sub-menu .submenu-container .use-case > .highlighted-item {
  color: var(--main-color)
}


.RecastAppExpander{
  z-index: 10
}

.image-box{
  width: 90%;
  background-color: #ffffff;
  display:flex;
  border-radius:20px;
  box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.5);
  background-color: #ffffff;
  min-height:35vh;
  height:510px;
}
.image-box>*{
  width:50%
}
.image-box>img{
  object-fit: cover;
  /* align-self: center; */
  /* height:100%; */
}
.image-box>img{
  /* min-height:100%; */
  /* height:auto */
  height: 100%;
  object-fit: contain;
  padding: 30px;
}
.image-box>.image-left{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.image-box>.image-right{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.image-box-txt{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5%;
  margin-top: 5%;
  overflow-y: scroll;
  height: 80%;
}
.image-box-txt *{
  margin:0;
  text-align: justify;
  line-height: 1.5;
  color: var(--font);
  margin-top: 1.5em;
  font-size: 20px
}
.image-box-txt a{
  align-self: center;
}
.image-box-txt button{
  border-radius: 24px;
  background-color: var(--main-color);
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 1rem;
  color: var(--font-on-main);
  text-align: center;
  cursor:pointer;
  border:none;
}
.image-box-title{
  align-self: flex-start;
  font-size: 2rem;
  font-family: escala-bold;
  text-align: left;
  color: var(--main-color);
  margin:0
}







.banner{
  height: 210px;
  width:100%;
  position: relative;
  background: var(--main-color);
  display:flex;
  align-items: center;
}
.banner>img{
  height:100%;
  position: absolute;
  top:0;
  right:0
}
.banner>p{
  font-size: 2em;
  color: var(--font-on-main);
  font-family: escala-bold;
  padding: 0 7%;
  white-space: nowrap;
}
.banner>.use-case-title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  padding: 0 4%
}
.banner>.use-case-title>*{
  font-size: 45px;
}
.banner>.use-case-title>p{
  white-space: break-spaces;
  font-size: 2em;
  color: var(--main-color);
  font-family: escala-bold;
  padding: 0 3%
}


.social-container{
  display:flex;
  justify-content: space-between;
  padding: 2% 40%
}
.social-container>*{
  transition: 0.2s
}
.social-container>*:hover{
  transform: scale(1.2)
}

.slider-container{
  height: 600px;
  width: 100%;
}
.slider-container .react-multi-carousel-dot>*{
  box-shadow: none ;
  width: 16px ;
  height: 16px ;
  background: var(--light-background);
  border: #999999 solid 1px;
  opacity: 1
}
.slider-container .react-multi-carousel-dot--active>*{
  background: var(--main-color);
  border: var(--main-color) solid 1px;
}



@media only screen and (max-width: 1024px) {
  .navbar-container{
    display:none
  }
  .compressed-navbar-container{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1%
  }
  .compressed-navbar-container>a{
    display:flex;
  }
  .compressed-navbar-container .search{
    width: 15%;
    display: flex;
    justify-content: center;
    visibility: hidden;
  }
  .language-container{
    position:absolute;
    bottom:20px
  }
  .separator{
    display: none
  }
  .main-navbar-container>.language-section{
    display:none
  }



  .image-box{
    height:35vh
  }
  .image-box-txt p{
    text-align: justify;
  }
  .image-box-txt .image-box-title{
    font-size: 1.25rem;
    text-align: left;
  }
  .image-box>img{
    align-self: auto
  }

  .banner{
    height:12vh
  }
  .banner>p{
    font-size: 1.5rem;
    margin:0;
    margin-bottom: 0.5em
  }

  .banner>p{
    font-size: 1.2rem
  }
  .banner>.use-case-title>p{
    font-size: 1.2rem
  }


  .react-multi-carousel-track {
    height:85%;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {
  .banner{
    height: 18vh
  }

  .image-box{
    height: 60vh;
  }
}

@media only screen and (max-width: 450px) {
  .image-box{
    flex-direction: column;
    height: auto
  }
  .image-box>img{
    min-height: auto
  }
  .image-box-txt{
    padding: 8% 5%;
    width:100%;
    height:50%
  }
  .image-box-txt p{
    font-size: 0.66rem
  }
  .image-box-txt button{
    font-size: 0.66rem;
    height: 2rem
  }
  .image-box-txt .image-box-title{
    font-size: 1.25rem
  }
  .image-box>.image-right, .image-box>.image-left {
    border-radius: 0;
    height: 50%;
    width:100%;
    bottom: 0
  }
  .image-box>.image-right{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .image-box>.image-left{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .social-container{
    padding: 2% 20% 4% 20%
  }

  .banner>p{
    font-size: 1.2rem
  }
  .banner>.use-case-title>p{
    font-size: 1rem
  }
  .banner>.use-case-title>svg{
    display:none
  }
  .banner>.use-case-title{
    width: 70%
  }
}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {
  .banner{
    height: 25vh
  }

  .image-box{
    width: 90%;
    background-color: #ffffff;
    display:flex;
    border-radius:20px;
    box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.5);
    background-color: #ffffff;
    min-height: 35vh;
    height: 60vh;
  }
  .image-box>*{
    width:50%
  }
  .image-box>img{
    object-fit: cover;
    min-height:100%;
    height:auto;
    max-height: 150vh
  }
  .image-box>.image-left{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .image-box>.image-right{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .image-box-txt{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5%
  }
  .image-box-txt *{
    margin:0;
    text-align: justify;
    line-height: 1.5;
    color: var(--font2);
    margin-top: 1.5em;
    font-size: 0.66rem
  }
  .image-box-txt button{
    border-radius: 24px;
    background-color: var(--main-color);
    font-size: 0.66rem;;
    height: 2rem;
    padding: 0 1rem;
    color: var(--font-on-main);
    text-align: center;
    cursor:pointer;
    border:none
  }
  .image-box-title{
    align-self: flex-start;
    font-size: 1rem !important;
    font-family: escala-bold;
    text-align: left;
    color: var(--font);
    margin:0
  }
}
