
.guide-main{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.guide-main *{
  color: var(--font2)
}
.guide-main .group-container{
  margin-bottom: 55px;
  padding: 50px 120px;
}
.guide-main .group-container>p{
  font-size: 32px;
  color: var(--main-color);
  font-family: escala-bold
}
.guide-main .question-container>p:first-child{
  font-size: 22px;
  font-family: escala-bold
}
.guide-main .question-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 125px
}
.guide-main .question-container>*{
  width: 50%
}
.guide-main .question-container .answer-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


@media only screen and (max-width: 1024px) {

}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {

}

@media only screen and (max-width: 450px) {

}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {

}
