
.main{
  display: flex;
  justify-content: space-between;
  height: 650px;
  align-items: flex-end;
  padding: 30px;
  background-image: url("/images/welcome-background-img.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
}
.image-container{
  min-width: 50%;
  max-width: 50%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.image-container > img{
  width: 50%;
  display: flex;
  justify-content: center;
}
.main .description{
  height: 100%;
  padding: 30px;
  color: var(--font2)
}
.main .description>.date{
  height: 100px;
  width: 420px;
  background-color: var(--main-color);
  color: white;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
}
.main .description .title{
  margin-top: 200px;
  font-size: 42px;
  font-family: escala-bold;
}
.main .description .text{
  width: 75%;
}


.tabs-container{
  padding: 50px 100px;
}


.needs-container{
  background-color: #F5F6F8;
  padding: 50px 100px;
}
.needs-container > .title{
  font-size: 42px;
  color: var(--font2);
  font-family: escala-bold
}


.need-container{
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: all 0.6s ease 0s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
}
.need-container .card-back{
  display:flex;
  flex-direction: column;
  justify-content: space-around;
}
.need-container .api-icon{
  object-fit: contain;
  background: none
}
.need-container .card-back>p{
  font-size: 1.5em;
  font-family: escala-bold;
  color: var(--font2);
}
.need-container .need-back-data{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.need-container .need-back-data>p{
  margin: 0.5em 0;
}
.need-container .card-back .apply{
  width: 100%;
  display: flex;
  justify-content: center;
}





@media only screen and (max-width: 1024px) {

}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {

}

@media only screen and (max-width: 450px) {

}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {

}
